<template>
  <div class="market-choice buy-page sell-page sell-system">
    <div class="title-md">
      {{ $t("sell-system-title") }}
    </div>

    <div class="market-choice__text">
      {{ $t("sell-char-notice") }}
    </div>

    <CharCard
      :is-big-card="true"
      :character-name="characterName"
      :nft-id="nftId"
      :character-image="characterImage"
      :price="5"
    />

    <Rules
      @click="isRulesModal = true"
      :title="$t('rules-system-title')"
      :text="$t('rules-system-text')"
    />

    <button class="game-btn blue-btn" @click="sellCshToSystem">
      {{ $t("sell-for-btn") }} SUB 5.00
    </button>

    <GameRulesModal
      v-if="isRulesModal"
      @close="isRulesModal = false"
      :rules-title="$t('rules-system-title')"
    />
  </div>
</template>

<script>
import CharCard from "../../components/character/CharCard";
import Rules from "../../components/common/Rules";
import GameRulesModal from "../../components/modals/GameRulesModal";
import LoadShoppieByIdMixin from "../../mixins/loadShoppieByIdMixin";
import SetProcessingMixin from "../../mixins/general/SetProcessingMixin";
import successes from "../../utils/successes";
import api from "../../api/api";
import { mapActions } from "vuex";
import contract from "../../api/contract";
import errors from "../../utils/errors";
export default {
  name: "SellSystem",
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  mixins: [LoadShoppieByIdMixin, SetProcessingMixin],
  data: () => ({
    isRulesModal: false,
  }),
  methods: {
    ...mapActions({
      loadBalance: "wallet/loadBalance",
    }),
    async sellCshToSystem() {
      try {
        this.setLoading(true);
        const [error, response] = await contract.sellCshToSystem(this.nftId);

        if (error) {
          this.setError({ message: error?.message || errors.UNKNOWN_ERROR });
          return;
        }

        if (response) {
          await api.sellCshToSystem({
            cshId: this.id,
            sig: {
              sig: response[0],
            },
          });
          this.loadBalance();
          this.setSuccess(successes.SOLD_TO_SYSTEM);
        }
      } catch (e) {
        this.setError({ message: e.response.data.error.message });
      } finally {
        this.setLoading(false);
      }
    },
  },
  mounted() {
    this.getCryptoshoppiesById(this.id);
  },
  components: { GameRulesModal, Rules, CharCard },
};
</script>

<style scoped></style>
